import React from "react";
import MethodeBox from "./MethodeBox";
import ResponsiveModal from "./ResponsiveModal";

const MetaBox = ({
	setRef,
	refIndic,
	territoire,
	responsiveModal,
	changeModal,
}) => {
	const getIndiceUi = () => {
		return (
			<MethodeBox refIndic={refIndic} setRef={setRef} territoire={territoire} />
		);
	};
	if (!refIndic[setRef]) return null;
	return (
		<>
			<div className="data-container d-none d-lg-block">{getIndiceUi()}</div>
			<ResponsiveModal
				size="sm"
				change={() => changeModal("indices")}
				title={
					(setRef && refIndic[setRef].composante === "IT") ||
					(setRef && refIndic[setRef].niveau > 1) ? (
						<>
							<i className="far fa-chart-bar"></i> Indice
						</>
					) : (
						<>
							<i className="fas fa-map-marked-alt"></i> Indicateur
						</>
					)
				}
				backdrop
				className="d-lg-none"
				visible={responsiveModal.includes("indices")}
				body={
					<MethodeBox
						refIndic={refIndic}
						setRef={setRef}
						territoire={territoire}
					/>
				}
			/>
		</>
	);
};

export default MetaBox;
