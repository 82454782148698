export default () => {
	return {
		VS: {
			id: "VS",
			composante: "",
			description: "Vulnérabilité Systémique",
			composition: ["RI", "G", "R", "I"],
			methode: "",
			niveau: 5,
			statut: "indice",
			acronyme: "VS",
			nom: "Vulnérabilité Systémique",
			lien1:
				"pdf/indices/locmariaquer_fiche_synthese_23_07_2020.pdf",
			texteLien1: "Exemple de restitution",
		},
		RI: {
			id: "RI",
			composante: "",
			description: "Risque",
			composition: ["A", "E"],
			methode: "Matrice",
			niveau: 4,
			statut: "indice",
			acronyme: "RI",
			nom: "Risque",
			lien1: "pdf/indices/RI.pdf",
			texteLien1: "Fiche méthodologique",
			lien2:
				"pdf/indices/RI_formule.png",
			texteLien2: "Formule",
		},
		A: {
			id: "A",
			composante: "AL",
			description: "Aléas",
			composition: ["A1", "A2"],
			methode: "Maximum des indicateurs",
			niveau: 3,
			statut: "indice",
			acronyme: "AL",
			nom: "Composante des aléas",
			lien1: "pdf/aleas/A.png",
			texteLien1: "Formule",
		},
		A1: {
			id: "A1",
			composante: "AL",
			description: "Erosion",
			composition: ["A101"],
			methode: "Distance érodée par an par 100m linéaires",
			niveau: 2,
			statut: "indice",
			acronyme: "ERO",
			nom: "Erosion",
			lien1: "",
		},
		A101: {
			id: "A101",
			composante: "AL",
			description: "Distance érodée par an par 100m linéaires",
			niveau: 1,
			acronyme: "DIST",
			nom: "Distance",
			lien1:
				"pdf/aleas/A101_EroDis.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "http://portail.indigeo.fr/geocms/maps/portail-jkyeggvu",
			texteLien2: "Couche de données",
			thematique: "ERO",
		},
		A2: {
			id: "A2",
			composante: "AL",
			description: "Submersion",
			composition: ["A201"],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "SUB",
			nom: "Submersion",
			lien1: "",
		},
		A201: {
			id: "A201",
			composante: "AL",
			description: "Hauteur de submersion potentielle",
			niveau: 1,
			acronyme: "HAUT",
			nom: "Hauteur",
			lien1: "pdf/aleas/A201_Sub.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "http://portail.indigeo.fr/geocms/maps/portail-bzayyexe",
			texteLien2: "Couche de données",
			thematique: "SUB",
		},
		A202: {
			id: "A202",
			composante: "AL",
			description: "Zonage de submersion",
			niveau: 1,
			acronyme: "ZONE",
			nom: "Zonage",
			lien1: "pdf/aleas/A202_Sub.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "http://portail.indigeo.fr/geocms/maps/portail-bzayyexe",
			texteLien2: "Couche de données",
			thematique: "SUB",
		},
		E: {
			id: "E",
			composante: "EN",
			description: "Enjeux",
			composition: ["E1", "E2", "E3"],
			methode: "Maximum des indices",
			niveau: 3,
			statut: "indice",
			acronyme: "EN",
			nom: "Composante des enjeux",
			lien1: "pdf/enjeux/E.png",
			texteLien1: "Formule",
		},
		E1: {
			id: "E1",
			composante: "EN",
			description: "Humains",
			composition: ["E101", "E105", "E107"],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "HUM",
			nom: "Humains",
			lien1: "pdf/enjeux/E1.png",
			texteLien1: "Formule",
		},
		E101: {
			id: "E101",
			composante: "EN",
			description: "Nombre d'habitants",
			niveau: 1,
			acronyme: "POP",
			nom: "Population",
			lien1:
				"pdf/enjeux/E101_Humain.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "HUM",
		},
		E102: {
			id: "E102",
			composante: "EN",
			description: "Nombre de bâtiments résidentiels",
			niveau: 1,
			acronyme: "BATI",
			nom: "Logements",
			lien1:
				"pdf/enjeux/E102_Logements.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "HUM",
		},
		E105: {
			id: "E105",
			composante: "EN",
			description:
				"Pourcentage de personnes de moins de 10 et de plus de 65 ans",
			niveau: 1,
			acronyme: "PERVUL-PCT",
			nom: "Population vulnérable",
			lien1:
				"pdf/enjeux/E105_PerVul.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "HUM",
		},
		E107: {
			id: "E107",
			composante: "EN",
			description: "Part des ménages à bas revenu",
			niveau: 1,
			acronyme: "BAREV-PCT",
			nom: "Ménages à bas revenu",
			lien1:
				"pdf/enjeux/E107_BasRev.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "HUM",
		},
		E2: {
			id: "E2",
			composante: "EN",
			description: "Economiques",
			composition: ["E201", "E206", "E109"],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "ECO",
			nom: "Economiques",
			lien1: "pdf/enjeux/E2.png",
			texteLien1: "Formule",
		},
		E109: {
			id: "E109",
			composante: "EN",
			description: "Capacité d’hébergement touristique",
			niveau: 1,
			acronyme: "TOURIST",
			nom: "Hébergement touristique",
			lien1:
				"pdf/enjeux/E109_HebTou.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "ECO",
		},
		E201: {
			id: "E201",
			composante: "EN",
			description: "Nombre d'emplois",
			niveau: 1,
			acronyme: "TRAV",
			nom: "Emploi",
			lien1:
				"pdf/enjeux/E201_Emplois.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "ECO",
		},
		E202: {
			id: "E202",
			composante: "EN",
			description: "Valeur immobilière moyenne (€/m²)",
			niveau: 1,
			acronyme: "VIMMO",
			nom: "Immobilier",
			lien1:
				"pdf/enjeux/E202_Valimmo.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "ECO",
		},
		E203: {
			id: "E203",
			composante: "EN",
			description: "Diversité des activités",
			niveau: 1,
			acronyme: "DIV",
			nom: "Résilience économique",
			lien1:
				"pdf/enjeux/E203_DivEco.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "ECO",
		},
		E3: {
			id: "E3",
			composante: "EN",
			description: "Structurels",
			composition: [
				"E301",
				"E302",
				"E303",
				"E304",
				"E308",
				"E307",
				"E103",
				"E306",
			],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "STR",
			nom: "Structurels",
			lien1: "pdf/enjeux/E3.png",
			texteLien1: "Formule",
		},
		E103: {
			id: "E103",
			composante: "EN",
			description: "Capacité d'accueil des Etablissements Recevant du Public",
			niveau: 1,
			acronyme: "ERP",
			nom: "Capacité d'accueil",
			lien1:
				"pdf/enjeux/E103_CapERP.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E301: {
			id: "E301",
			composante: "EN",
			description: "Localisation des établissements industriels à risque",
			niveau: 1,
			acronyme: "INDRISQ",
			nom: "Suraccident",
			lien1:
				"pdf/enjeux/E301_IndRis.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E302: {
			id: "E302",
			composante: "EN",
			description: "Densité des routes",
			niveau: 1,
			acronyme: "ROUTES",
			nom: "Réseaux routiers",
			lien1:
				"pdf/enjeux/E302_ResoROUTES.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E303: {
			id: "E303",
			composante: "EN",
			description: "Part du linéaire côtier protégé par des ouvrages",
			niveau: 1,
			acronyme: "DIG",
			nom: "Protections contre la mer",
			lien1:
				"pdf/enjeux/E303_ProMer.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E304: {
			id: "E304",
			composante: "EN",
			description: "Localisation du bâti résidentiel sans étage refuge",
			niveau: 1,
			acronyme: "REFUG",
			nom: "Logements à risque",
			lien1:
				"pdf/enjeux/E304_LogRis.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E110: {
			id: "E110",
			composante: "EN",
			description: "Emprise au sol des bâtiments résidentiels",
			niveau: 1,
			acronyme: "EMPBAT",
			nom: "Emprise du bâti",
			lien1:
				"pdf/enjeux/E110_BATIresid_empr.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "HUM",
		},
		E206: {
			id: "E206",
			composante: "EN",
			description: "Emprise au sol des bâtiments à vocation économique",
			niveau: 1,
			acronyme: "BATECO",
			nom: "Bâtiments économiques",
			lien1:
				"pdf/enjeux/E206_BATindus_empr.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "ECO",
		},
		E308: {
			id: "E308",
			composante: "EN",
			description: "Présence d'établissements de secours",
			niveau: 1,
			acronyme: "PRESSEC",
			nom: "Présence secours",
			lien1:
				"pdf/enjeux/E308_SECOURScentres.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E306: {
			id: "E306",
			composante: "EN",
			description: "Patrimoine historique et architectural",
			niveau: 1,
			acronyme: "PATRIM",
			nom: "Patrimoine culturel",
			lien1:
				"pdf/enjeux/E306_PatHis.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E307: {
			id: "E307",
			composante: "EN",
			description: "Présence de ports",
			niveau: 1,
			acronyme: "PORTS",
			nom: "Ports",
			lien1:
				"pdf/enjeux/E307_PORTS.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STR",
		},
		E4: {
			id: "E4",
			composante: "EN",
			description: "Agricoles et naturels",
			composition: ["E205", "E402"],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "AGRNAT",
			nom: "Agricoles et naturels",
			lien1: "pdf/enjeux/E4.png",
			texteLien1: "Formule",
		},
		E205: {
			id: "E205",
			composante: "EN",
			description: "Surface occupée par l'agriculture",
			niveau: 1,
			acronyme: "AGRI",
			nom: "Agriculture",
			lien1:
				"pdf/enjeux/E205_TerAgr.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "AGRNAT",
		},
		E402: {
			id: "E402",
			composante: "EN",
			description: "Nombre de zonages environnementaux",
			niveau: 1,
			acronyme: "PATNAT",
			nom: "Patrimoine naturel",
			lien1:
				"pdf/enjeux/E402_PatNat.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "AGRNAT",
		},
		G: {
			id: "G",
			composante: "GE",
			description: "Gestion",
			composition: ["G1", "G2", "G3", "G4", "G5"],
			methode: "Moyenne des indicateurs",
			niveau: 4,
			statut: "indice",
			acronyme: "GE",
			nom: "Composante de la gestion",
			lien1: "pdf/gestion/G.png",
			texteLien1: "Formule",
			lien2:
				"pdf/indices/locmariaquer_focus_sur_la_gestion_23_07_2020.pdf",
			texteLien2: "Exemple d’analyse de la gestion",
		},
		G1: {
			id: "G1",
			composante: "GE",
			description: "Maîtrise de l’urbanisation",
			composition: ["G101", "G102"],
			methode: "Moyenne des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "MURBA",
			nom: "Maîtrise de l’urbanisation",
			lien1: "pdf/gestion/G1.png",
			texteLien1: "Formule",
		},
		G101: {
			id: "G101",
			composante: "GE",
			description: "Contraintes de constructibilité en zone d’aléas",
			niveau: 1,
			acronyme: "URBA",
			nom: "Urbanisme",
			lien1:
				"pdf/gestion/G101_Urba.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "MURBA",
		},
		G102: {
			id: "G102",
			composante: "GE",
			description: "Etat général du PPRL",
			niveau: 1,
			acronyme: "PPRL",
			nom: "PPRL",
			lien1:
				"pdf/gestion/G102_PPRL.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "MURBA",
		},
		G2: {
			id: "G2",
			composante: "GE",
			description: "Stratégie locale",
			composition: [
				"G103",
				"G104",
				"G105",
				"G106",
				"G107",
				"G108",
				"G110",
				"G111",
			],
			methode: "Moyenne des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "STRATLOC",
			nom: "Stratégie locale",
			lien1: "pdf/gestion/G2.png",
			texteLien1: "Formule",
		},
		G103: {
			id: "G103",
			composante: "GE",
			description: "Mise en place de la compétence GEMAPI",
			niveau: 1,
			acronyme: "GEMAPI1",
			nom: "Compétence GEMAPI",
			lien1:
				"pdf/gestion/G103_GEMAPI.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G104: {
			id: "G104",
			composante: "GE",
			description: "Etat des ouvrages GEMAPI",
			niveau: 1,
			acronyme: "GEMAPI",
			nom: "Etat des ouvrages GEMAPI",
			lien1:
				"pdf/gestion/G104_OuvGEMAPI2.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G105: {
			id: "G105",
			composante: "GE",
			description: "Capacités humaines sur les risques côtiers",
			niveau: 1,
			acronyme: "HUM",
			nom: "Investissement humain",
			lien1:
				"pdf/gestion/G105_CapHum.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G106: {
			id: "G106",
			composante: "GE",
			description: "Démarche locale de gestion des risques",
			niveau: 1,
			acronyme: "STRATE",
			nom: "Démarche locale (PAPI, SL)",
			lien1:
				"pdf/gestion/G106_Strate.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G107: {
			id: "G107",
			composante: "GE",
			description: "Acteurs partenaires de la démarche locale",
			niveau: 1,
			acronyme: "ACTEUR",
			nom: "Intégration des acteurs",
			lien1:
				"pdf/gestion/G107_IntActDem.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G108: {
			id: "G108",
			composante: "GE",
			description: "Intégration extraterritoriale",
			niveau: 1,
			acronyme: "EXTRA",
			nom: "Intégration extraterritoriale",
			lien1:
				"pdf/gestion/G108_DemExtra.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G110: {
			id: "G110",
			composante: "GE",
			description: "Mise en œuvre des actions du PAPI",
			niveau: 1,
			acronyme: "PAPI2",
			nom: "Mise en œuvre du PAPI",
			lien1:
				"pdf/gestion/G110_MeOPAPI2.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G111: {
			id: "G111",
			composante: "GE",
			description: "Etat de la relocalisation dans la stratégie locale",
			niveau: 1,
			acronyme: "RELOC",
			nom: "Relocalisation",
			lien1:
				"pdf/gestion/G111_Reloc.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "STRATLOC",
		},
		G3: {
			id: "G3",
			composante: "GE",
			description: "Gestion de crise",
			composition: ["G301", "G302", "G303"],
			methode: "Moyenne des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "CRI",
			nom: "Gestion de crise",
			lien1: "pdf/gestion/G3.png",
			texteLien1: "Formule",
		},
		G301: {
			id: "G301",
			composante: "GE",
			description: "Moyens d’alerte",
			niveau: 1,
			acronyme: "ALERTE",
			nom: "Alerte",
			lien1:
				"pdf/gestion/G301_Alerte.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CRI",
		},
		G303: {
			id: "G303",
			composante: "GE",
			description: "Mise à jour du PCS",
			niveau: 1,
			acronyme: "PCS",
			nom: "Plan Communal de Sauvegarde",
			lien1:
				"pdf/gestion/G303_MJ_PCS.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CRI",
		},
		G302: {
			id: "G302",
			composante: "GE",
			description: "Intégration du SDIS dans le PCS",
			niveau: 1,
			acronyme: "SDIS",
			nom: "Intégration",
			lien1:
				"pdf/gestion/G302_InstSDIS.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CRI",
		},
		G4: {
			id: "G4",
			composante: "GE",
			description: "Sensibilisation",
			composition: ["G201", "G202", "G204", "G205", "G206"],
			methode: "Moyenne des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "SEN",
			nom: "Sensibilisation",
			lien1: "pdf/gestion/G4.png",
			texteLien1: "Formule",
		},
		G201: {
			id: "G201",
			composante: "GE",
			description:
				"Evènements de sensibilisation dans les établissements scolaires",
			niveau: 1,
			acronyme: "SCOL",
			nom: "Scolaires",
			lien1:
				"pdf/gestion/G201_EvetScol.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SEN",
		},
		G202: {
			id: "G202",
			composante: "GE",
			description: "Présence d’associations sur les risques côtiers",
			niveau: 1,
			acronyme: "ASSO",
			nom: "Association",
			lien1:
				"pdf/gestion/G202_Asso.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SEN",
		},
		G204: {
			id: "G204",
			composante: "GE",
			description: "Repères inondation et de recul du trait de côte",
			niveau: 1,
			acronyme: "REP",
			nom: "Repères",
			lien1:
				"pdf/gestion/G204_Rep.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SEN",
		},
		G205: {
			id: "G205",
			composante: "GE",
			description: "Modes de diffusion du DICRIM ",
			niveau: 1,
			acronyme: "DICRIM1",
			nom: "DICRIM Diffusion",
			lien1:
				"pdf/gestion/G205_DICRIM1.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SEN",
		},
		G206: {
			id: "G206",
			composante: "GE",
			description: "Analyse pédagogique du DICRIM",
			niveau: 1,
			acronyme: "DICRIM2",
			nom: "DICRIM Pédagogie",
			lien1:
				"pdf/gestion/G206_DICRIM2.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SEN",
		},
		G5: {
			id: "G5",
			composante: "GE",
			description: "Connaissance",
			composition: ["G203"],
			methode: "Moyenne des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "CONN",
			nom: "Connaissance",
			lien1: "",
		},
		G203: {
			id: "G203",
			composante: "GE",
			description: "Publications scientifiques sur les risques côtiers",
			niveau: 1,
			acronyme: "PUBLI",
			nom: "Publications scientifiques",
			lien1:
				"pdf/gestion/G203_PblSci.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONN",
		},
		R: {
			id: "R",
			composante: "RE",
			description: "Représentations",
			niveau: 4,
			statut: "indice",
			acronyme: "RE",
			composition: ["R1", "R2", "R3"],
			methode: "",
			nom: "Composante des représentations",
			lien1:
				"pdf/indices/locmariaquer_focus_sur_les_representations_23_07_2020.pdf",
			texteLien1: "Exemple d’analyse des représentations",
			lien2: "pdf/indices/enquete_representations.pdf",
			texteLien2: "Participer à l'Enquête",
		},
		R1: {
			id: "R1",
			composante: "RE",
			description: "Conscience du risque",
			composition: ["R101", "R102", "R103", "R104", "R105", "R106", "R107"],
			methode: "",
			niveau: 2,
			statut: "indice",
			acronyme: "CONSRISQ",
			nom: "Conscience des risques",
			lien1: "",
		},
		R101: {
			id: "R101",
			composante: "RE",
			description: "Expérience personnelle du risque",
			niveau: 1,
			acronyme: "EXPDI",
			nom: "Expérience personnelle",
			lien1:
				"pdf/representations/R101_EXPDI.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R102: {
			id: "R102",
			composante: "RE",
			description: "Expérience indirecte du risque",
			niveau: 1,
			acronyme: "EXPIND",
			nom: "Expérience indirecte",
			lien1:
				"pdf/representations/R102_EXPIN.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R103: {
			id: "R103",
			composante: "RE",
			description: "Recherche d'informations",
			niveau: 1,
			acronyme: "RECHINFO",
			nom: "Recherche d'infos",
			lien1:
				"pdf/representations/R103_RECHINFO.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R104: {
			id: "R104",
			composante: "RE",
			description: "Place des risques côtiers",
			niveau: 1,
			acronyme: "PROLOC",
			nom: "Place des risques",
			lien1:
				"pdf/representations/R104_PROLOC.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R105: {
			id: "R105",
			composante: "RE",
			description: "Intérêt du risque",
			niveau: 1,
			acronyme: "INT",
			nom: "Intérêt risque",
			lien1:
				"pdf/representations/R105_INT.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R106: {
			id: "R106",
			composante: "RE",
			description: "Connaissance des programmes de gestion",
			niveau: 1,
			acronyme: "CONPR",
			nom: "Connaissance programmes",
			lien1:
				"pdf/representations/R106_CONPR.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R107: {
			id: "R107",
			composante: "RE",
			description: "Pratiques individuelles",
			niveau: 1,
			acronyme: "PRATIND",
			nom: "Pratiques individuelles",
			lien1:
				"pdf/representations/R107_PRATIND.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "CONSRISQ",
		},
		R2: {
			id: "R2",
			composante: "RE",
			description: "Evaluation des institutions et des pratiques collectives",
			composition: ["R201", "R202", "R203", "R204", "R205", "R206"],
			methode: "",
			niveau: 2,
			statut: "indice",
			acronyme: "EVAPRACOL",
			nom: "Evaluation des pratiques collectives",
			lien1: "",
		},
		R201: {
			id: "R201",
			composante: "RE",
			description: "Confiance institutions nationales",
			niveau: 1,
			acronyme: "CONFNATION",
			nom: "Institutions nationales",
			lien1:
				"pdf/representations/R201_CONFNATION.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R202: {
			id: "R202",
			composante: "RE",
			description: "Confiance institutions locales",
			niveau: 1,
			acronyme: "CONFLOCAL",
			nom: "Institutions locales",
			lien1:
				"pdf/representations/R202_CONFLOCAL.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R203: {
			id: "R203",
			composante: "RE",
			description: "Confiance dans les institutions et acteurs médiateurs",
			niveau: 1,
			acronyme: "CONFMEDIA",
			nom: "Confiance médiateurs",
			lien1:
				"pdf/representations/R203_CONFMEDIAT.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R204: {
			id: "R204",
			composante: "RE",
			description: "Renforcement de l'existant",
			niveau: 1,
			acronyme: "RENFEX",
			nom: "Renforcement existant",
			lien1:
				"pdf/representations/R204_RENFEX.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R205: {
			id: "R205",
			composante: "RE",
			description: "Règlementation et recul du bâti",
			niveau: 1,
			acronyme: "REGREC",
			nom: "Règlementation bâti",
			lien1:
				"pdf/representations/R205_REGREC.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R206: {
			id: "R206",
			composante: "RE",
			description: "Mesures d'évacuation",
			niveau: 1,
			acronyme: "MESEVAC",
			nom: "Mesures d'évacuation",
			lien1:
				"pdf/representations/R206_MESEVAC.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "EVAPRACOL",
		},
		R3: {
			id: "R3",
			composante: "RE",
			description: "Sens du lieu",
			composition: ["R301", "R302"],
			methode: "Maximum des indicateurs",
			niveau: 2,
			statut: "indice",
			acronyme: "SENSLIEU",
			nom: "Sens du lieu",
			lien1: "",
		},
		R301: {
			id: "R301",
			composante: "RE",
			description: "Attachement au lieu",
			niveau: 1,
			acronyme: "ATTLIEU",
			nom: "Attachement au lieu",
			lien1:
				"pdf/representations/R301_ATTLIEU.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SENSLIEU",
		},
		R302: {
			id: "R302",
			composante: "RE",
			description: "Activités en lien avec la mer",
			niveau: 1,
			acronyme: "ACTMER",
			nom: "Activités en lien avec la mer",
			lien1:
				"pdf/representations/R302_ACTMER.pdf",
			texteLien1: "Fiche méthodologique",
			thematique: "SENSLIEU",
		},
		I: {
			id: "I",
			composante: "IT",
			composition: [],
			description: "Indices",
			niveau: 5,
			statut: "indice",
			acronyme: "IT",
			nom: "Indices composites",
			lien1: "",
		},
		I2: {
			id: "I2",
			composante: "IT",
			composition: [],
			description: "Transverses",
			niveau: 2,
			statut: "indice",
			acronyme: "TRANS",
			nom: "TRANS",
			lien1: "",
		},
		I101: {
			id: "I101",
			composante: "IT",
			nom: "Indice de crise",
			description: "Indice en période de crise",
			composition: ["A", "E1", "G3"],
			methode: "Matrices",
			niveau: 1,
			statut: "indice",
			acronyme: "VULCRI",
			lien1:
				"pdf/indices/I101_VulGesCri.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "pdf/indices/I101.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
		I102: {
			id: "I102",
			composante: "IT",
			nom: "Indice de bâti",
			description: "Indice de bâti résidentiel",
			composition: ["A", "E110", "G1"],
			methode: "Matrices",
			niveau: 1,
			statut: "indice",
			acronyme: "VULBAT",
			lien1:
				"pdf/indices/I102_VulBati.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "pdf/indices/I102.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
		I103: {
			id: "I103",
			composante: "IT",
			nom: "Indice de bâti de plain-pied",
			description: "Indice de bâti de plain-pied à la submersion",
			composition: ["A201", "E303", "E304", "G104"],
			methode: "Matrices",
			niveau: 1,
			statut: "indice",
			acronyme: "VULPLA",
			lien1:
				"pdf/indices/I103_VulPla.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "pdf/indices/I103.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
		I104: {
			id: "I104",
			composante: "IT",
			nom: "Indice risque + gestion",
			description: "Indice risque + gestion",
			composition: ["A", "E", "G"],
			methode: "Matrices",
			niveau: 1,
			statut: "indice",
			acronyme: "RISGES",
			lien1:
				"pdf/indices/I104_RisGes.pdf",
			texteLien1: "Fiche méthodologique",
			lien2: "pdf/indices/I104.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
		I105: {
			id: "I105",
			composante: "IT",
			nom: "Risque lié à l'érosion",
			description: "Risque lié à l'érosion",
			composition: ["A1", "E"],
			methode: "Matrice",
			niveau: 1,
			statut: "indice",
			acronyme: "RISERO",
			lien1:
				"pdf/indices/I105_matrice.png",
			texteLien1: "Matrice",
			lien2:
				"pdf/indices/I105_formule.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
		I106: {
			id: "I106",
			composante: "IT",
			nom: "Risque lié à la submersion",
			description: "Risque lié à la submersion",
			composition: ["A2", "E"],
			methode: "Matrice",
			niveau: 1,
			statut: "indice",
			acronyme: "RISSUB",
			lien1:
				"pdf/indices/I106_matrice.png",
			texteLien1: "Matrice",
			lien2:
				"pdf/indices/I106_formule.png",
			texteLien2: "Formule",
			thematique: "TRANS",
		},
	};
};
