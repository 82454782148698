import { isEmpty, isEqual } from "lodash";
import React, { useEffect, useState } from "react";

import config from "../config.json";
import Feature from "../containers/Feature";
import ResponsiveModal from "./ResponsiveModal";

const useTemplateOnly = config.templates?.useTemplateOnly;

/**
 *
 * @param {boolean} isVisible to show or hidde offCanvas
 * @param {any} infos from GetFeatureInfos requested on cell click
 * @param {string} tpl URL to load template from public folder
 * @returns
 */
const InfosBox = ({
	infos = {},
	tpl = config.templates?.infos,
	url = "",
	urlCompare = "",
	onLoad = () => {},
	onClose = () => {},
}) => {
	let Mustache = require("mustache");
	let sanitizeHtml = require("sanitize-html");
	const [show, setShow] = useState(false);
	const [view, setView] = useState({});
	const [content, setContent] = useState("");
	const [template, setTemplate] = useState("");

	const handleClose = () => {
		onClose();
		setShow(false);
	};

	// effect on visible change
	useEffect(() => {
		if (!isEmpty(infos) && !isEqual(infos, view)) {
			setView(infos);
			setShow(!isEmpty(infos));

			let tplInfos = infos.properties;
			const dirtyRender = Mustache.render(template, tplInfos);
			const cleanRender = sanitizeHtml(dirtyRender, {
				// to custom sanitize-html config, see https://www.npmjs.com/package/sanitize-html
				allowedTags: false,
				allowedAttributes: false,
			});
			setContent(cleanRender);
		}
	}, [infos?.id]);

	// effect on init
	useEffect(() => {
		if (!template) {
			// load template
			fetch(tpl)
				.then((r) => r.text())
				.then((t) => setTemplate(t));
		}
	}, []);

	useEffect(() => {
		if (url || urlCompare) {
			onLoad(url, urlCompare);
		}
	}, [url, urlCompare]);
	if (!url) return null;

	const getContent = (responsive) => {
		if (isEmpty(infos)) return <p>Aucune information à afficher.</p>;
		return (
			<>
				{!useTemplateOnly && (
					<div className="infos-body row">
						<span className="col-md-12 col-lg-5 py-0">
							{!responsive && (
								<h3 className="offcanvas-title" id="offcanvasBottomLabel">
									Informations
								</h3>
							)}
							<div
								className="row"
								dangerouslySetInnerHTML={{
									__html: content,
								}}></div>
						</span>
						<span className="col-md-12 col-lg-7 py-0">
							<Feature />
						</span>
					</div>
				)}
				{useTemplateOnly && (
					<span
						className="row"
						dangerouslySetInnerHTML={{ __html: content }}></span>
				)}
			</>
		);
	};
	return (
		<>
			<div
				className="offcanvas-parent flex-fill d-none d-lg-block"
				id="offCanvas-parent">
				<div
					id="infosCanvas"
					style={{ position: "absolute", height: "auto" }}
					data-bs-backdrop="false"
					className={`offcanvas offcanvas-bottom ${
						show ? "show" : ""
					} bg-primary text-white p-1`}>
					<div className="offcanvas-header">
						<button
							type="button"
							className="btn-close text-reset"
							data-bs-dismiss="infosCanvas"
							aria-label="Close"
							onClick={handleClose}></button>
					</div>
					<div className="offcanvas-body small py-0">{getContent()}</div>
				</div>
			</div>
			<ResponsiveModal
				size="sm"
				change={handleClose}
				title={"Informations"}
				className="d-lg-none"
				visible={show}
				body={getContent(true)}
			/>
		</>
	);
};

export default InfosBox;
